<div class="container">
  <form [formGroup]="formulario">
    <mat-card style="margin-top: 10px;">
      <mat-card-header>
        <mat-card-title>
          Buscar Extrato de Ocorrências
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row wrap">
          <div fxFlex="100%">
            <mat-form-field appearance="fill" fxFill fxFlex="100%">
              <mat-label>Intervalo de Data *</mat-label>
              <mat-date-range-input [formGroup]="formulario" [rangePicker]="picker">
                <input matStartDate formControlName="datainicio" placeholder="Data inicio">
                <input matEndDate formControlName="datafim" placeholder="Data fim">
              </mat-date-range-input>
              <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
              <mat-error *ngIf="formulario.controls.datainicio.hasError('matStartDateInvalid')" > Data início inválida</mat-error>
              <mat-error *ngIf="formulario.controls.datafim.hasError('matEndDateInvalid')" > Data fim inválida</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap.gt-md="5px grid" fxLayoutGap.gt-sm="5px grid">
            <mat-form-field appearance="fill" fxFlex="100%" fxFlex.gt-lg="50%" fxFlex.gt-md="50%" fxFlex.gt-sm="100%">
              <mat-label>CPF</mat-label>
              <input matInput type="text" mask="000.000.000-00" formControlName="cpf">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="100%" fxFlex.gt-lg="50%" fxFlex.gt-md="50%" fxFlex.gt-sm="100%">
              <mat-label>Parte do nome</mat-label>
              <input matInput type="text" formControlName="nome">
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxFlex="100%" fxLayoutAlign="end" fxLayout="row wrap">
          <button class="button-opacity" fxFlex="100%" fxFlex.gt-lg="20%" fxFlex.gt-md="20%" fxFlex.gt-sm="100%" (click)="voltar()">Voltar</button>
          <button class="button-rounded" fxFlex="100%" fxFlex.gt-lg="20%" fxFlex.gt-md="20%" fxFlex.gt-sm="100%" (click)="submit()">Buscar</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>

  <div *ngFor="let ocorrencia of ocorrencias">
    <mat-card style="margin-top: 10px;" *ngIf="ocorrencia != null">
      <mat-card-header>
        <mat-card-title>
          Ocorrência nº {{ ocorrencia?.cod_ocorrencia }}
          <mat-divider></mat-divider>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxFlex="100%" fxLayout="row wrap">
          <div fxFlex="50%" fxLayout=" wrap">
            <div fxFlex="100%">
              <div fxFlex="3%"></div>
              <div fxFlex="5%">
                <div fxFlex="100%"><mat-icon class="icon-display">health_and_safety</mat-icon></div>
              </div>
              <div fxFlex="92%" fxLayout=" wrap">
                <div fxFlex="100%" class="text-title">Natureza</div>
                <div fxFlex="100%" class="text-subtitle"> {{ ocorrencia.detalhe_ocorrencia?.natureza_ocorrencia }}</div>
              </div>
            </div>
            <div class="space"></div>
            <div fxFlex="100%">
              <div fxFlex="3%"></div>
              <div fxFlex="5%">
                <div fxFlex="100%"><mat-icon class="icon-display">person_outline</mat-icon></div>
              </div>
              <div fxFlex="92%" fxLayout=" wrap">
                <div fxFlex="100%" class="text-title" *ngIf="ocorrencia.pessoas_envolvidas?.vitima != null">Vítima</div>
                <div fxFlex="100%" class="text-subtitle" *ngIf="ocorrencia.pessoas_envolvidas?.vitima != null"> {{ ocorrencia.pessoas_envolvidas?.vitima?.nome }}</div>
                <div fxFlex="100%" class="text-title" *ngIf="ocorrencia.pessoas_envolvidas?.solicitante != null">Solicitante</div>
                <div fxFlex="100%" class="text-subtitle" *ngIf="ocorrencia.pessoas_envolvidas?.solicitante != null"> {{ ocorrencia.pessoas_envolvidas?.solicitante?.nome }}</div>
              </div>
            </div>
            <div class="space"></div>
            <div fxFlex="100%">
              <div fxFlex="3%"></div>
              <div fxFlex="5%">
                <div fxFlex="100%"><mat-icon class="icon-display">event</mat-icon></div>
              </div>
              <div fxFlex="92%" fxLayout=" wrap">
                <div fxFlex="100%" class="text-title">Data</div>
                <div fxFlex="100%" class="text-subtitle">{{ ocorrencia.detalhe_ocorrencia.data_ocorrencia | date: 'dd/MM/yyyy HH:mm' }}</div>
              </div>
            </div>
          </div>
          <div fxFlex="50%">
            <div fxFlex="100%" fxLayoutAlign="center center">
              <a class="btn-print" target="_blank"  [routerLink]="['detalhes_impressao']" [queryParams]="{ codprojeto: ocorrencia?.cod_ocorrencia }">
                <mat-icon class="icon-display">printer</mat-icon>
                <div> Imprimir</div>
              </a>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>





