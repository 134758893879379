import { SnackbarComponent } from './snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private durationInSeconds = 2;
  private color
  constructor(private _snackBar: MatSnackBar) { }

  sucesso(label: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: this.durationInSeconds * 1000,
      data: {
        label: label,
        class: 'sucesso'
      }
    });
  }

  alerta(label: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: this.durationInSeconds * 1000,
      data: {
        label: label,
        class: 'alerta'
      }
    });
  }

  erro(label: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: this.durationInSeconds * 1000,
      data: {
        label: label,
        class: 'erro'
      }
    });
  }

  setDurationInSeconds(duration: number) {
    this.durationInSeconds = duration;
  }
}
