import { ExtratoOcorrenciasModule } from './servicos/extrato-ocorrencias/extrato-ocorrencias.module';
import { AuthGuard } from './guards/auth.guard';
// Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Components
import { ExtratoOcorrenciasComponent } from './servicos/extrato-ocorrencias/extrato-ocorrencias.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { RelatorioAttmComponent } from './servicos/relatorio-attm/relatorio-attm.component';
import { PrincipalComponent } from './principal/principal.component';
import { AcidentesTransitoComponent } from './servicos/acidentes-transito/acidentes-transito.component';

const routes: Routes = [
  {
    path: '',
    component: PrincipalComponent
  },
  {
    path: 'relatorio_attm',
    component: RelatorioAttmComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'acidentes_transito',
    component: AcidentesTransitoComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'extrato_ocorrencias',
    loadChildren: () => ExtratoOcorrenciasModule,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
