import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from '../../app.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-relatorio-attm',
  templateUrl: './relatorio-attm.component.html',
  styleUrls: ['./relatorio-attm.component.css']
})

export class RelatorioAttmComponent implements OnInit  {
  urlSafe: SafeResourceUrl = null;
  constructor(
    private sanitizer: DomSanitizer,
    private localStorage: LocalStorageService,
    private appService: AppService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    /* if ( document.getElementById("mainRoot").classList.contains('container') ) {
      document.getElementById("mainRoot").classList.remove('container');
      document.getElementById("mainRoot").classList.add('container-fluid');
    } */

    // "resource" => ["dashboard" => 57]

    /* "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHBpcmVkX2F0IjoiMjAyMi0wNi0wMyAwNjoyOTowNCIsImV4cCI6MTY1NDI0ODU0NCwicmVzb3VyY2UiOnsiZGFzaGJvYXJkIjo1N30sInBhcmFtcyI6e319.CiVlofdcFP78XoxlO3njM0ujKIgC7VHJaj6z4CA-XE0" */

    /* const url = 'https://bi.bombeiros.to.gov.br/embed/dashboard/' + ''
          + '#bordered=false&titled=false&origin=https://sistemas.bombeiros.to.gov.br';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url); */

    this.gerarTokenDashboardBI(57);
  }

  gerarTokenDashboardBI(numDashboard: number) {
    const jsonDashboard = JSON.stringify({
      dashboard: numDashboard
    });
    this.spinner.show();
    this.appService.gerarTokenDashboardBI(jsonDashboard).subscribe(
      (resultJSON: JSON) => {
        console.log(resultJSON);
        this.spinner.hide();
        if (!resultJSON['error']) {
          const url = 'https://bi.bombeiros.to.gov.br/embed/dashboard/' + resultJSON['token']
          + '#bordered=false&titled=false&origin=https://sistemas.bombeiros.to.gov.br';
          console.log(url);
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        } else {
          this.snackbar.erro('Falha o gerar token');
        }
      }, error => {
        this.snackbar.erro('Falha o gerar token. Aguarde um pouco ou procure a ASSEINFO');
        this.spinner.hide();
        console.log(error);
      }
    )
  }
}
