import { SharedService } from './../../shared/shared.service';
import { SnackbarService } from './../../shared/snackbar/snackbar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from './../../app.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location, DatePipe } from '@angular/common';

@Component({
  selector: 'app-extrato-ocorrencias',
  templateUrl: './extrato-ocorrencias.component.html',
  styleUrls: ['./extrato-ocorrencias.component.css']
})
export class ExtratoOcorrenciasComponent implements OnInit {
  formulario: FormGroup;
  page = 1;
  ocorrencias = [];

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private datePipe: DatePipe,
    private appService: AppService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackbarService,
    private sharedService: SharedService
  ) {
    this.formulario = this.formBuilder.group({
      datainicio: [{ value: null, disabled: true }, [Validators.required]],
      datafim: [{ value: null, disabled: true }, [Validators.required]],
      cpf: [''],
      nome: ['']
    });
  }

  ngOnInit(): void {
  }

  voltar() {
    this.location.back();
  }

  submit() {
    const query =
    '?data_inicio=' + this.datePipe.transform(this.formulario.get('datainicio').value, 'yyyy-MM-dd')
    + '&data_fim=' + this.datePipe.transform(this.formulario.get('datafim').value, 'yyyy-MM-dd')
    + '&page=' + this.page;

    console.log(query);

    this.spinner.show();
    this.appService.buscaOcorrenciasPorData(query).subscribe(
      (resultJSON: JSON) => {
        //console.log(resultJSON);
        this.sharedService.update(resultJSON);
        this.ocorrencias = [];
        if (!resultJSON['error']) {
          if (resultJSON['Ocorrencias_por_data'] != null) {
            for (let i = 0; i < Object.keys(resultJSON['Ocorrencias_por_data']).length; i++) {
              const ocorrencia = resultJSON['Ocorrencias_por_data'][i];
              // Insere no array
              this.ocorrencias.push(ocorrencia);
            }
            console.log(this.ocorrencias);
          } else {
            this.snackbarService.alerta('Não foram encontrados registros');
          }
        } else {
          this.snackbarService.erro('Falha ao buscar registros');
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.snackbarService.erro('Falha ao buscar registros. Tente novamente mais tarde ou procure o CBMTO');
        this.spinner.hide();
      }
    );
  }
}
