import { SharedService } from './../shared/shared.service';
import { AppService } from './../app.service';
import { Observable, throwError } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanLoad, RouterStateSnapshot, Router, Route } from "@angular/router";
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanLoad {

  currentURl: string;

  constructor(
      private appService: AppService,
      private sharedService: SharedService,
      private router: Router,
      private localStorage: LocalStorageService) {
        this.currentURl = this.router.url;
      }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.verificarAcesso(state);
  }

  private verificarAcesso(state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.appService.verificaToken().pipe(
        map((response: JSON) => {
            this.localStorage.clear('redireciona');
            // console.log(response);
            return true;
        }), catchError((err, caught) => {
            if (state != null) {
              this.localStorage.store('redireciona', state.url);
            }
            console.log(err);
            // Limpa local storage
            this.localStorage.clear();
            // Redireciona para o login
            this.router.navigate(['/']);
            // Anuncia atualização
            this.sharedService.update('serviços');
            return  throwError(err);
        })
      );
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
      return this.verificarAcesso(null);
  }
}
