
// Angular
import { IConfig } from 'ngx-mask-2';
import { NgxMaskModule } from 'ngx-mask-2';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { AppService } from './app.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// Components
import { AppComponent } from './app.component';
import { PrincipalComponent } from './principal/principal.component';
import { RelatorioAttmComponent } from './servicos/relatorio-attm/relatorio-attm.component';
import { ExtratoOcorrenciasComponent } from './servicos/extrato-ocorrencias/extrato-ocorrencias.component';
// Modules
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from 'src/material.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ExtratoOcorrenciasModule } from './servicos/extrato-ocorrencias/extrato-ocorrencias.module';
import { AcidentesTransitoComponent } from './servicos/acidentes-transito/acidentes-transito.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    RelatorioAttmComponent,
    AcidentesTransitoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    ExtratoOcorrenciasModule,
    NgxMaskModule.forRoot(options),
    NgxWebstorageModule.forRoot(),
    SharedModule,
    MaterialModule
  ],
  providers: [
    AppService,
    NgxSpinnerService,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ],
  schemas: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
