import { AppService } from './../../app.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dialog-login',
  templateUrl: './dialog-login.component.html'
})
export class DialogLoginComponent {
  // Atributos
  login: string;
  senha: string;

  constructor(
    public dialogRef: MatDialogRef<DialogLoginComponent>,
    private spinnerService: NgxSpinnerService,
    private localStorage: LocalStorageService,
    private appService: AppService
  ) { }

  cancelar() {
    this.dialogRef.close('cancel');
  }

  logar() {
    const json = JSON.stringify({
      cpf: this.login,
      senha: this.senha
    });

    this.spinnerService.show();
    // Busca login no back
    this.appService.fazerLogin(json).subscribe(
      (resultLogin: JSON) => {
        console.log(resultLogin);
        this.spinnerService.hide();
        if (!resultLogin['error']) {
          this.localStorage.store('token', resultLogin['token']);
          this.localStorage.store('codusuario', resultLogin['codusuario']);
          this.dialogRef.close('success');
        } else {
          this.localStorage.clear();
          this.dialogRef.close('error');
        }
      }, error => {
        this.localStorage.clear();
        this.dialogRef.close('error');
        this.spinnerService.hide();
        console.log(error);
      }
    );
  }
}
