<mat-toolbar color="primary">
  <mat-toolbar-row class="container">
    <span class="link" *ngIf="btnVoltar" (click)="voltar()"><mat-icon aria-hidden="false" aria-label="Voltar">arrow_back_ios</mat-icon></span>
    <a [routerLink]="['/']"><img src="assets/logo-branca.png" height="45px"/></a>
    <span class="menu-spacer"></span>
    <button [matMenuTriggerFor]="menu" mat-icon-button class="menu-icon" aria-label="">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="serviceLogin.openDialogLogin()" *ngIf="!logado"><mat-icon aria-label="login do usuario">account_circle</mat-icon>Login</button>
      <button mat-menu-item (click)="logoff()" *ngIf="logado"><mat-icon aria-label="fazer logoff">logout</mat-icon>Sair</button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
<app-spinner></app-spinner>
