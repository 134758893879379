import { AppService } from './../../app.service';
import { Subscription } from 'rxjs';
import { NavigationStart, Router, NavigationEnd } from '@angular/router';
import { SharedService } from './../shared.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from 'ngx-webstorage';
import { Location } from '@angular/common';
import { DialogLoginService } from '../dialog-login/dialog-login.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  public btnVoltar = false;
  logado = false;

  // Subscriptions
  private tokenSubscription: Subscription;
  private sharedSubscription: Subscription;

  // SVG Image
  logoBombeirosSVG = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="4px" width="50px" height="59px" viewBox="0 0 50 59" enable-background="new 0 0 50 59" xml:space="preserve">  <image id="image0" width="50" height="59" x="0" y="0"
  href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAA7CAYAAAA5MNl5AAAABGdBTUEAALGOfPtRkwAAACBjSFJN
AAB6JQAAgIMAAPn/AACA6QAAdTAAAOpgAAA6mAAAF2+SX8VGAAAABmJLR0QA/wD/AP+gvaeTAAAa
UElEQVRo3rV6d5BdxZnvr7tPujlO1iTNKI0yyhIyQRI2XlhsEGH9DE5rrw32OpV3gar3vHgLu96u
vcbhlb22C2ODTY4rMLCAAEkEgWQFZkZhRpNnNOnGc88595zT3e+Pq5EECAntrruq/7i3Tnd/v/7y
9zW5ak4aM0NKiZCuo2NuE1rqqxEIx7H8c7eib3gU27Y9jey+pzHRewRMwVkHF0BNlGJ1K8WcRYHW
hR16v1kSUgIgOMeQApwEkW//KV7ZM4CdO15GXdhA9u1XEEuEsXTBbNRXpcCIwO4sw67eCWiMQDnX
vjODEEASBa4PMHr2b12/QvBHNwW3jhWJse0Zs29Xl48PA8R3Oermt+DqJQ0gsvfDkvfhgVBKYZEw
hguApp8FMIASB5Y2EyMZYrcfn+YDU8PeK9WaHHE5EecC4zOJsPQhpfzQIM4LCEAQTSTAJeDJDybG
FUBbDK2XrdVv51XxJXMVZ2lKlW29Pd5t2WPe04Z2dp5IF6CzZ0MQ9j8PREqAex4Wr9sMb3o/PGsC
hJ75oLInsaKZNV+6Mfi3Ys5CuJ2HUdvqLB41pZEZddBYV9GhM54DgPpAsakVQp5Tm/5rHBGCwwgn
sWndBrDOJyCVMx/EfYKaesatMoMYnkC+L4vknABaFgZvYUW+RCXud1V65rVECkimIVfVDsL9vwwQ
APB9FzLZgUtmPQkGD2eSYuEBuYS22lQoJt44gqDPkcvriLSmL1mdBCZeOQ6inhkIFQJT1RfDjcxB
SLh/OSBEcrhqDKFgCDGex/u4TwBbot5f0Pr5EikhquXh2gxepoAJUotQUx1qvJGQCl464/4cGKz5
CAQ5P7E6byAUElyL4BdTK9D3+ksg6rvtsOtKrJzPLrupyuggxzOINhmwpgU8BUBuDGJB0yUqQTP1
Sdf7WSlhRlqQDc8HE2UA+ocj6r8CBAAk59DnrMUyZTsUReB0++VxiSSVSYX5qJ4dAnEklEXVGDs0
iYYGBaOvdCHs0UXRoOx6l3UlFSU/NmsLPDUG5hX/shwBAMF9BOMNWL95McLOAfDTNMX3gVAgvSzQ
XgdMHwI6VkBXLNQMZRCqqoHwpmCnyPXRBvEQe4/RkyDIJeeDcu+8QXwgEIkPdkaESEhC8cTey7Hz
/j7MuGsCwCwLbLhA2X/teP+N4fokDK8eVEzAGw1i1A6jGJwNFlGu7OwWsxU9cIxRDRISRAgglICX
bgWFi7O5wg+iTYHgJ/krCQEBSalUXUWAFwHpAZUYzPM8+L4PIQQoAFVXEQoGITgHqSgniwbZ6sFe
MuuBRwIDDXUpT6H9QkgIgSbhuZ4IBJpoVTKhBELK1a7nPeUJ7whAAOFDIoakooEqCqQQUFUVQggI
IU4AAAhIm0rVakLIG4QQidNAKTzVCBACxS5AKRUIFeR+VWhbooHYc57EF6SUI5qmzZ03f/7GJUuX
Pux5XoEpCgrTU9j7MIVlFWCEwmCUJoJMfwFUBk0rbwoR9Y1QiHueD98XVCqEJKKGGjQoEz7/V893
N5pe4Srh++Cug+Y1S9E3OIySWQAApNPptmQyeWEgENjuue6g9Pyr4kbk1+lQssp13b8WovwfnCZO
aihrWbQcvhFBKF0LpZgl8Lwlju2uD6pqe7yx7ZNzPrLlGsct31ldXXNRX3//jzK5nDedyQKUYOPH
rkAsmURP535YVsmWjDxLKaW+x5sAWYxFwyWnXHbtctkjBCIaCSKbL5KhibHvT+YmflQo5jMsFMPi
yz+FDdd/Cbt378Yjjz2G3t4+HDl6tHHFqpXPNTe3fHF+R8cVNDP5LeKUwkXHft2087/m6ZbMpJLA
6MgwKCFgq1euQDgY1GqbW+rTTS35qWNd/1nMZUemHH5h9cVX1s+Zv6C5f2CQ7drx6uNPPfLggwOH
OzHQ+WfUN7Vgw5bLceknrsPSCy+GVSxg4Ej3GPd5Xle0Twvfo7WpOAP3GfdcFg7oLBEJU8f1BrOF
qZsjNTWjF3z8elx00zfRvuJCCM7R2FCHN55/Br5bBhFiWjLlk/PmzWtcsGRp45pNl9GDr73yu/HJ
4Rvq5y8e77jiM8hkMq0D/X05TdOgTExMoGPRwr+64dprNx45evRb5ZKJ4vGR3zh6eG3LvHlfOPjO
QVQFVCwRU25dRyqiqbSoQSDdUA2zVIRjRbBgxVos+NVavPDgfXj4xz844GSygZZFK5OLLr0Y4WQa
AgD1XXi5KcijXQdnrVqbXXPdF0EDIbiOg7JlgjIFCmMIZUfgZabBKFuQI2V0R8IIhULoWNiBL/zk
7j89dt89JZuqKOSLWNjR8f3ly5fdIYU8pAghQEA+Vl1T/ek333j9qXT7gkNXf+Erf10qmdctmDcX
3Z2dePz/fg+tMeOzdXVVS81y8XOeV97vV9bVUUprXMeellKyi6++Iblo9bpNZj7H5i1bcUbrstx3
lxCq/Fxy/nSxkB+iBFBVNckUtZ0Q8rAEKaqEfaYqGv9pyrejub277P2hUMDQdcydN+/HeqrWt7PZ
3dz3Njc2N92gqdrzQ0NDhxRN08A5j3R3dwfD0dhLqaqqwuKFHbGurk5wLqbS0fA3D3W+s34sGPzK
BXzB8qZZNdtNP/tZ6ZSera6p3RYMBpcDKAGgAIINrW2AEMBddyG3axcK09NwFQWkuhqhpUuR3Lo1
pba23gJKb0ml0jMmkwHAlOct4SUrX5tI/p+qZAJlr3x4cmLkhunu7rVEyJ9w7tdpmvZIKp02121Y
H85mc5gYP54oFgtQDMMAF9z0PB/Lly8jUspYf38fDhw4gJ27Xvtya23Vox78+ybz2e7d+7v+NV8o
Jlobqh9qm7/wYCIauaD87LNwIpGwSgi0d94B6+oCefttiF27EABgAOAAPADOH/6AsTvugLJ2LdSr
roLe0cHo8eMoHziA4Ny5qLnxxq9fuOVyHHtzB3Jm/rEyd74GIzY6PTm1r9N/p7atre27K1eugOe5
4cnJSRw6fBiHu7qtwcEBKI7jIJvJbj9+/PgXN2zYAN/3MDZ2HKtXrwH3/anOdw7iqr+/FTsfu/9n
o4e6OjOT4//e8s1/aG9buWElv/deiH/+Z+SPHoUBIDpztboOGo9Df2/wJyXgujBffBHmiy/CAhA4
sabc0IDEtdfi+tvvwG/+8as/zGQnvzM4Po2F67Yg7ngYHBqc1nUd9fV18H2Ovr4+HDl8BHv27t2T
z+fAGurqYNtWv6brV8fjsXRDQwMIIZiYmEBLS8uS0dGRPSs+ckk2XNvIo9XVfWuu+MTwlV/99vXq
jh3AL38JbWwMMSEQMgwogQBIIAAoZ4l8GIMWCCBsGIjoOoxAAAHDgJicBHQd4SuuxJorr9lVNW/p
fz747HY6r2Nh4ML16y+ZO3feD9esXRNxXQ+c+3A9H5PjE88cPLD/h6qqgjU2NUMK7vq+93oul99U
KpWS4XAYjFHYtlXXMnv2l/p6e2psp/zC0gsvbrjs6mt/z44eTTm33QZ9aAg4fhxQ1Up14owxzQnt
OVNkMbOGECiEwNy9G+rFF0Npat7Apdzxxmuv5UOh0IP1jbPuWLhoUYQA0DQNkUgEiXhiR9mxb2po
mFVcsmw5lIaqAIYnSrAse++xnp514+PjN7355psfTyaTcSHE+Nw5c7b19/c9XFtTazumGS3m89Eg
gOzQEIJCgLCz5NYCEFEF0BnohAOwD84zKKXIFgpQSyUEgUwqmZhcsmTJ9K7Xdn1ldHT0hlQ6/dFY
LBYWnI/WVFc/dvGll9xfVV3jVtXUwC2MgX3v5k2QUkPOFgChlmPbr5tm8fee5/3qwP4DfzQM/e1Q
KGQFg0Houj4xNDDwzKxVq65TGxtD5eefhwEAngdQUtGBmQgSAISEmzKAWABs0no3kNPLKYSgUCjA
/tSnUP2d78A0i1/nnP+pt7cH7xx8J2db1s5SyfwdJH69b9+fHxBC7F+7dh23rBIG9r0Ae2QHqJQS
C1vTWNGegkIJQCgorUxN06Cq6smTheCwbavryMEDv4hffTWslhZIxgAhISngpbQKdTPJhgC4Doiw
AojTZIsDMkaAmZTX95GlFKmbb4YEDjm2/VspgaXLlkPTNFD2bpp0IwC3bOPo7mcxNdQNQimokIDj
cdTEDayaE0c0wOBzefJcKSQMw0AkEoGiKAgEAshNTz/qAZwtXgy/XAYoAfEALiQKyxMAYYB/ImpV
fSB4IgETAHzAaRMQhgTKEiAEvuNANjUhsnIVXN97hlLmSymwYMECzJk7B2WnfNLocQEwWcaftz+M
7PggFNUAQKDoCr6oUAy4nnw+ElCwsj2OsZwLkwOcC3icY3br7JN6SSmF53njjpQmi8djwvcrSksA
Y9KFHzSRv7wGkTdzKIc0eO0UPEzAlyShj5bgtFpQbAY2ipPZkO/7MFIpEF0D5XwwFAqhYsV1XHfd
9ejuPgTP59AVYGlbCq0JD3Z+EkxRQQnWMSpXKZ6Pi6sT4lPjGbrJ8+VLjBI0pw14XCJKkojrHIFg
EJ5bPqECEoQQAkIIhABRVcB1K2BUitCAg0zdNKZvqIKIeDBacxCEodxhwDtUhjFOoO9hgPpuM6ad
EEchRMx1XRACeJ6HVWvWYOsnr8DhvTswOy2hqzEQIkCJhKbKtdGQfKlkk+eUyRy9pSrO87VJ8eJ4
ln7e9fBbKSUogFlVEUBmYRcyMMJxCCEgpYCu69U6ECpPTUE1DMByAFrRFR6gIIoK0TaG2KICqKzU
p2SrBlkfAPsDg4xSkIJfMcuUgFEGWBbg+2AKWyPlTC1AwvN81EcBtzYCQMLnEowCuo7r42FxX6mM
ezMF9jWFEORMh9wc0BGoSYi7x7O0ruzh+5RUZF74LvKTA9AjCUgpwX2OaF1qCysWGTt0CNABGSeQ
AQoogJsm8FYLBJsLoNyHnImmqAs6K4DyRhVqiUIZISBcAi7ApAo6NQVMToLV1S2XElEhRIExBYXM
JEYHeiEJA6kYQgRU+dVERPysaJNH7TI+T0jl4kEIULTI54oWflWX4ncGdfmAlIgAAKEUpcwYBOcV
y0qJ1jBnzmed/9iGUDYD4ZlwExR2DVBqIvCqOSjLgKp+RblP8ylSseErQZTDFHY9QTlNwSMEJKRB
mZqC7D4EAtSpirJJSgmmqOh5ZzdKxSwopZASWkiXv0zHxM/yJXK/aZHrZ3T3ZCxBCVCwyN/5gug1
SfGZiSxtLjlkKyF0xLWLcO0CVD2CWCK5Oer7i6wH/ohQyQImPbBpBboQAAecmICjA+5aAi0uT2T9
AFUA1w5DHNIQ6vSgjLqVIIsCoBQUgPvyduiXXgJV17d6nD9edko41vU2mKJCCMQjQflgVUJcli2S
J0sOuYlR8BlXRGfMmjwBpmiRL01m6e/SMbE2FpQvS5CLuO/BnBqBBEh9e/st3ssvQztyFMhmAZVV
rkOrRICazaD0K3D6KSyrEhFKBji2AXM4CuO4DjZhAfoJP8IIQAFNVeHefz+QzUJV1U2apsfGh3pR
yEyAUGVRLCRfqkqIy6by5Kl8iXxaAr4QgC8IpAQoF4DlUJTLBFwQUALXdMgXJrL0vkRUtCfC8hkQ
duPkQCeEW2ysSaW22Nu3Qy+VAMd5d4zFAR4SYPMFAs/p8B8PIfd2EKUDtbCeagN7iKPUZEHWRwDv
NLmTEkowCNnTA7FzJwhQ49rm6t0vPgouyOZ4SD6Xjovlkzn6bN6kN0hJTN8ncH0Cp0wg5EzHSp6K
7wCAEXDHJZ8/nqHhmqT4BAj9fS7nLKK8PKQBqhgbAxXv6Q2c8OKlpT4iu1TwLAE0BvMCDrVaQfCF
aRhFF/Y+jsyGWiRsHyxnnwpbToiXv2sXtCuvxGDPwcsK06Prk/HA7cmo0CZzZGexRK6nFPaZYjVF
AuBnrHkRzy6T68am5O9mVfG/CSj+zZpSKT6LbBZkxhHOXKoClOs4Iu8oYHlSac+ZAvoEhcIo9DEL
CFAE84D24jjslgiCrgfq8JM3SAA43d3QANiFyb9vSAseDnNtZEJuL1j4JKWiwPkZKIWEMlDQ4Avy
vg6UlBJGIODVGfSnBXNyc7yqDqnGjhQASMN4fz+QAcFRVkkFZ0wIlYBrAKYKsBNtLkaguEC4pwip
k3dt4gMoV1cjCoCHGjSHRhxe4n6ouu0HC+atyKuaBvkeSSCEwHMdKGWfnLGPJiVU1+f/T0u1XReo
XhY+1t3tDg88KRcvXQE0NsIjBBo9VY0nM+2M0wv0BFBsCZrzK5YE7/6GOKf18IRAHkBw48bKJ6P9
6O0nDPHa40vbFv6yYXbHbYTgofe3FivxGj0RJp1pbg0Gg1+cGh97ccfjT/bufeA+bWzPTtUuO4jd
eCMyigKkUgDnlWLDzMJ3cYlAzbugE+aZW8GkAgCeBz+fB50/H4lrroFVLGDy7R1YpjjlVGbwhwff
ev341NTkL3zPDbpuGZ7nwfN9eJzD8wU8j4OtW78ehNKTkzIFIBSE0q/Obm6e2/P0I3+yjnZubmyo
NWTZgZaqQfulm2EWiyjv3Qtd10FVtVKKnxFgckroiQ/Q8nu6p1JWchjOgUAAaGoCWbECsZ/8BLSl
RT7wo++Llx5/lFZVpbTZ0cDmKcdT1KoGIxgM/dTMTpVL08dRmhpDYXwI5tQYzKkxKFNvvHSKRZRB
CUWgJdLQElWFeDwRrU/Gb5NahW1Tkrz1Rmf34gXrJ43a734XhXXrMHH33dAOH0awWITBOYjnAaZZ
IZaegQu+X/l/zhxg0SJg7Vpg0yaQBQtgc463duwgkYamYcsX1quvvb1g4fw5iuhY3eAVspP9f3q1
2p4czku3DEoEHNc6xfy5BodvFeFbJrxSAeXpCZRHBi4llnlrZE5HYtmyZejb83opUzJv92e13ypD
8a8n43E1GAohsnixiG3dStiWLbCam1H0PJRtG5quV2TWcU6BmfldVQV87WvALbcAW7cCmzfDr6ry
u7u76ZOPPNK5c8erb1xw4UXtdc0tVx09sK+umKibn5i/GLGJvpAxPvgJz3O6heQ9jFIIAhDKQCgD
W1gVPSlWkhJoivaNRDhyT300lM7bZWhN7Whdsuyg1jz3J64eua1sWWtGRkdGPM+NaoztKRaLL2j1
9c3xVauM8DXXABs3YrqnB3JyEnooVOEOIUCxCDQ0AHfdBXzpS0BTExCNZvsHB3513z33sMPd3Q2u
W74yn89vGx4a/Ep1a/v/MhpnJ2kiHTePdmH87dcQSyRj8WjsBhBSFkS8zsUpx8E60uGT7NGJ8vOo
EfzfjfW1zNB12MeHuwaK1mPlQHSjJ8k3vLKzmDJ6Zz6f/8rAwMCGQDC4OpVK3TfQ2/t3x8fGjlNG
6+Kts2ui11yDgpRw9u5FMBSCGBmBTCZBHnwQ+OhH4QuRcyzrZ/v277vpD/feSzzP+0ZVVdVdhJC7
i8XidKlUenJkeMgr2fZh7rl3jL35ykv5yenLMvmiGjQMmozGt0DKRleUnzolWqkABCQ4JFRCr9SY
slJVVZiO9ULezl9NU7X3my6/r5jPPSOEuIMx9oSUsuj7/rbR0dFPVtdUX9/W3r4rm8n8tu9Y72+m
J6c69UBgSfWll6Ysw4Dz7LMwolHQBx8ELroIlm1vK+bz1+7Zs+eBxx59NMIofaGpufmAEOJvFEUR
pmnCdd1JQsiLQsrnKaW9xaGefW7Zerlc9i/K5ApJ1/ORswoPZEvFXcWSjWLJBpuXDJ7kCId8FQKz
bdfZ4VN+k4TMKVVNYMFwkQB9lNI8Ywy80qUyPc99a3x8/Kbm5ubLZ82a9ZCqqtOZTKZzbHj4j4SQ
5lmbNy9yKUXg298GNm4UZsm81basr3Z1dU4/8cQTEEL8vnX27CW6blwBkBFFUWCaJizLAqUUhBBQ
SmEO9sK1S0OSkkc915tnOtYDQvHvLPsenLILx3XB5qdOASGEuFLKRwQVTxuaygGAVc0CDYQAKUEp
BWMMvl/J+iilw6ZpWo5tXzW7rb09Gon+UQiBP+/fZ48M9D9qTY1/bO6Nn5klGxu9sWNHbvKl/Pfe
3mN4+OGH4Xveltra2jtTqfTPAXk3IeTk3rlcDpTSiqOjFOZgD7hjA5QUXcHvV1T6ciwaBGWVjJsQ
gjM+WCLneIx0+ssdRVHuOnLkyGvdXV1/parqVrNkorOzMygU9d9ee/KxjgO7duDgnx7zn9/2pOU4
LnqO9sC2LC0cDv9bOp2ekFL+U2U/CSkFDMMAO0vRj3wAfed4eXV2ECe4yIUQ33jrrd0ik838oFSy
4qqq/ipVVf1NqqjRh/7pH7DvuacCOc6eIJT+bSqVgqIoN6fT6UWqqn6Lc396punJOQdj7EQ2eH7P
nN4rWqAgYIxC11QA8n2iRQgBf08ISikdLZVKVaZpfkxR1Y9EIpGPQ8qXOrc/95LMTl1QW1fT2W+L
7kQqfVPZdXeWTPOeVCr1gpTyViklTs1KuemEwlcijROi5TtWxUVICV1TEYkYIKex4bw5ws8UR1fA
fLers3Oot6dn/QUrVpKIptyRGex7nRkB6PD6pZX91qs7d8YYo0+2tbXZnIsvc85PtqArs7K3rp/f
8w3gPF8+zNzcB4wMCPnm+Pj4I5ZVQmtLK5OQQ1IICGBYULVcMk0xMjycFEJcJ6UY/KCtFEU5b9E6
L46cAwgopY96nnfXvr17EU5X3774I5si3LFlkelSGOHvRcMhaprmj8vl8sOcc5xp+r4PVVUrVusv
xZEPMxhjtx871tvxzNPbLuu47MpLBxqakaHKl6NCQlXVf+Gc/+MHieeJy5jxU8B5MOV/HAgAm4B8
4ujRni/39/VdZUh+EVHYG1wL3amo2jac4y2WBHCub/7bQAhjJ2z4Oa6KUFtYpR9PvPXq/TXwxjqW
zPuXiVD1NqoTUBo4+1JCKs8A/1IcoYoKc7QP1tggCD3HMgKUpyfgFXI8lqrh2fxUmebGIMYYXMKA
c1yGBKDG2+AZiXNf2vkAIZTBK2RRPPg2hF2qFKzPOiQoZdA1o2SVy7uGx6ZHJGTFH38YayQF5JQN
OWcFEAie+3sA/x8aWPbYZ/WPbAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNS0yMFQwMzowMzoy
NyswMzowMAuw898AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDUtMjBUMDM6MDM6MjcrMDM6MDB6
7UtjAAAAAElFTkSuQmCC" />
</svg>`;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private localStorage: LocalStorageService,
    private router: Router,
    private location: Location,
    public serviceLogin: DialogLoginService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.iconRegistry.addSvgIconLiteral('logoSVG', this.sanitizer.bypassSecurityTrustHtml(this.logoBombeirosSVG));
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.btnVoltar = event.url !== '/';
        console.log("btnVoltar " + this.btnVoltar);
      }
    });

    this.sharedSubscription = this.sharedService.getUpdate().subscribe(
      update => {
        if (update === 'serviços') {
          this.isLogado();
        }
      }
    );

    this.isLogado();
  }

  isLogado() {
    this.tokenSubscription = this.appService.verificaToken().subscribe(
      (resultJSON: JSON) => {
        if (!resultJSON['error']) {
          this.logado = true;
        } else {
          this.localStorage.clear();
          this.logado = false;
        }
      }, error => {
        console.log(error);
        this.logado = false;
        this.localStorage.clear();
      }
    );
  }

  logoff() {
    this.localStorage.clear();
    this.sharedService.update('serviços');
    this.router.navigate(['/']);
    this.logado = false;
  }

  voltar() {
    this.location.back();
  }

  ngOnDestroy(): void {
      if (this.tokenSubscription != null) {
        this.tokenSubscription.unsubscribe();
      }

      if (this.sharedSubscription != null) {
        this.sharedSubscription.unsubscribe();
      }
  }
}
