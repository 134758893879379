// Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { DetalhesImpressaoComponent } from './detalhes-impressao/detalhes-impressao.component';
import { ExtratoOcorrenciasComponent } from './extrato-ocorrencias.component';

// Guard
import { AuthGuard } from './../../guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: ExtratoOcorrenciasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'detalhes_impressao',
    component: DetalhesImpressaoComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExtratoOcorrenciasRoutingModule { }
