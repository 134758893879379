<div class="container">
  <mat-card style="margin-top: 10px;">
    <mat-card-content>
      <div fxLayout="row wrap">
        <!-- Cabecalho -->
        <div fxFlex="100%" fxLayoutAlign="center">
          <div fxFlex="20%" fxLayoutAlign="center">
            <img src="assets/brasaoMenor.png" height="45px"/>
          </div>
          <div fxFlex="40%" fxLayout="row wrap">
            <div fxFlex="100%" fxLayoutAlign="center">CORPO DE BOMBEIROS MILITAR DO TOCANTINS</div>
            <div fxFlex="100%" fxLayoutAlign="center">SISTEMA DE OPERAÇÕES DO CORPO DE BOMBEIROS - SIOCB</div>
            <div fxFlex="100%" fxLayoutAlign="center">EXTRATO DE OCORRÊNCIAS - {{ cidade }}</div>
          </div>
          <div fxFlex="20%" fxLayoutAlign="center">
            <img src="assets/miniLogoQuadrada.png" height="45px"/>
          </div>
        </div>
        <!-- Linha -->
        <div fxFlex="100%" style="margin-top: 10px;">
          <mat-divider></mat-divider>
        </div>
        <!-- Data de Emissao -->
        <div fxFlex="100%" style="margin-top: 10px;" fxLayoutAlign="end">
          Extrato emitido às {{ dataextrato }} do dia {{ dataextrato }} via SIOP
        </div>
        <!-- Linha -->
        <div fxFlex="100%" style="margin-top: 10px;">
          <mat-divider></mat-divider>
        </div>
        <!-- Informacoes  -->
        <div fxFlex="100%" fxLayoutAlign="center" fxFill>
          <div fxFlex="50%" fxLayout="row wrap">
            <div fxFlex="100%" fxLayoutAlign="center">
              <mat-icon>list</mat-icon>
              Informações da Ocorrência nº {{ ocorrencia }}
            </div>
            <div fxFlex="100%" fxLayoutAlign="center">
              <div fxFlex="20%" class="subTitulo" fxLayoutAlign="end">Natureza</div>
              <div fxFlex="80%"></div>
            </div>
            <div fxFlex="100%" fxLayoutAlign="center">
              <div fxFlex="20%" class="subTitulo" fxLayoutAlign="end">Endereço</div>
              <div fxFlex="80%"></div>
            </div>
            <div fxFlex="100%" fxLayoutAlign="center">
              <div fxFlex="20%" class="subTitulo" fxLayoutAlign="end">Data/Hora</div>
              <div fxFlex="80%"></div>
            </div>
            <div fxFlex="100%" fxLayoutAlign="center">
              <div fxFlex="20%" class="subTitulo" fxLayoutAlign="end">Solicitante</div>
              <div fxFlex="80%"></div>
            </div>
          </div>
          <div fxFlex="50%" fxLayout="row wrap">
            <div fxFlex="100%">
              <mat-icon>list</mat-icon>
              Viaturas Empenhadas
            </div>
            <div fxFlex="100%">
              VIATURA (V1) {{ viatura }}
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

