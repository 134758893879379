import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalhes-impressao',
  templateUrl: './detalhes-impressao.component.html',
  styleUrls: ['./detalhes-impressao.component.css']
})
export class DetalhesImpressaoComponent implements OnInit {

  ocorrencia = '123';
  cidade = "";
  dataextrato = "2022-06-15 23:00:00";
  viatura = "";
  constructor() { }

  ngOnInit(): void {
  }

}
