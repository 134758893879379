<h1 mat-dialog-title>Login </h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" class="formInput">
    <mat-label>CPF</mat-label>
    <input matInput type="text" [(ngModel)]="login" mask="000.000.000-00">
  </mat-form-field>

  <mat-form-field appearance="fill" class="inputLogin">
    <mat-label>Senha</mat-label>
    <input type="password" matInput [(ngModel)]="senha">
  </mat-form-field>
</div>
<div mat-dialog-actions style="justify-content: center;">
  <button mat-stroked-button color="warn" (click)="cancelar()">Cancelar</button>
  <button mat-flat-button color="primary" (click)="logar()">Logar</button>
</div>
