import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'card-link',
  templateUrl: './card-link.component.html',
  styleUrls: ['./card-link.component.css']
})
export class CardLinkComponent implements OnInit {

  @Input() titulo: string;
  @Input() descricao: string;
  @Input() path: string;
  @Input() icone: string;
  @Input() type: string;
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  openPath() {
    console.log(this.path);
    console.log(this.type)
    if (this.type == 'link') {
      // Exibe arquivo em uma nova aba do navegador
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = this.path;
      link.click();
    } else if (this.type == 'servico') {
      console.log(this.path);
      this.router.navigate([this.path]);
    }
  }
}
