import { SnackbarService } from './../snackbar/snackbar.service';
import { SharedService } from './../shared.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogLoginComponent } from './dialog-login.component';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogLoginService {

  constructor(
    private dialog: MatDialog,
    private sharedService: SharedService,
    private snackbarService: SnackbarService
  ) { }

  openDialogLogin() {
    const dialogRef = this.dialog.open(DialogLoginComponent, { width: '250px', height: '300px' });

    // Apos o fechamento do dialog
    dialogRef.afterClosed().subscribe(result => {
      // Verifica resultado do dialog
      console.log(result);
      if (result == 'success') {
        console.log('anuncio emitido');
        this.sharedService.update('serviços');
        this.snackbarService.sucesso('Logado com sucesso!');
      } else if (result == 'error') {
        this.snackbarService.erro('Falha no login');
      }
    });
  }
}
