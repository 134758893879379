import { DialogLoginService } from './../shared/dialog-login/dialog-login.service';
import { SnackbarService } from './../shared/snackbar/snackbar.service';
import { AppService } from './../app.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { SharedService } from './../shared/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit, OnDestroy {
  // Atributos
  servicos = [];
  // Subscriptions
  private updatesSubscriptions: Subscription;
  private servicosSubscription: Subscription;
  constructor(
    public dialog: MatDialog,
    private sharedService: SharedService,
    private localStorage: LocalStorageService,
    private snackbarService: SnackbarService,
    private appService: AppService,
    private loginService: DialogLoginService) {

    }

  ngOnInit(): void {
    this.inicializaServicos();
    this.verificaAtualizacoes();
    this.buscarServicos();
  }

  inicializaServicos() {
    this.servicos = [
      {titulo: "Intranet", descricao: "Serviços Internos do CBMTO", path: "https://intranet.bombeiros.to.gov.br", icone: "account_balance", tipo: 'link'},
      {titulo: "Protege", descricao: "Simpósio Tocantins 2016", path: "https://protege.bombeiros.to.gov.br/2016/", icone: "fire_extinguisher", tipo: 'link'},
      {titulo: "Eventos", descricao: "Eventos do CMBTO", path: "https://eventos.bombeiros.to.gov.br/", icone: "military_tech", tipo: 'link'},
      {titulo: "Prevenir", descricao: "Solicitação de PTS, Análise de Projetos e Eventos", path: "https://prevenir.bombeiros.to.gov.br", icone: "whatshot", tipo: 'link'},
      {titulo: "Siocb", descricao: "Sistema de Operações do CBMTO", path: "https://siocb.bombeiros.to.gov.br", icone: "fire_hydrant_alt", tipo: 'link'},
      {titulo: "App Intranet", descricao: "Aplicativo da Intranet do CBMTO", path: "https://play.google.com/store/apps/details?id=br.gov.to.bombeiros.intranet_cbmto", icone: "adb", tipo: 'link'},
      {titulo: "Fundação", descricao: "Fundação Pró Tocantins", path: "https://sistemas.bombeiros.to.gov.br/fundacao", icone: "foundation", tipo: 'link'},
      {titulo: "ABMTO", descricao: "Anammese FireCorpus", path: "https://sistemas.bombeiros.to.gov.br/abmto", icone: "fitness_center", tipo: 'link'}
    ];
  }

  verificaAtualizacoes() {
    // Verifica atualizacoes emitidas pela aplicacao
    this.updatesSubscriptions = this.sharedService.getUpdate().subscribe(
      update => {
        if (update == 'serviços') {
          this.inicializaServicos();
          this.buscarServicos();
        } else {
          console.log(update);
        }
      }
    );
  }

  buscarServicos() {
    if (this.localStorage.retrieve('codusuario') != null) {
      const query = "?codusuario=" + this.localStorage.retrieve('codusuario');
      console.log(query);
      this.servicosSubscription = this.appService.buscaServicos(query).subscribe(
        (servicosJSON: JSON) => {
          console.log(servicosJSON);
          if (!servicosJSON['error']) {
            for (let i = 0; i < Object.keys(servicosJSON['data']).length; i++) {
              let servico = {
                titulo: servicosJSON['data'][i]['SERVICO'],
                descricao: servicosJSON['data'][i]['SERVICO'],
                path: servicosJSON['data'][i]['PATH'],
                icone: 'list_alt',
                tipo: 'servico'
              };
              this.servicos.push(servico);
            }

            if (Object.keys(servicosJSON['data']).length == 0) {
              // console.log("Não possuem serviços cadastrados ou ativos para esse usuário");
              this.snackbarService.alerta('Não possuem serviços cadastrados ou ativos para esse usuário');
            }
          } else {
            console.log(servicosJSON);
          }
        }, error => {
          if (error.status == 401) {
            // Limpa local storage
            localStorage.clear();
          }
          console.log('Erro ao buscar servicos ' + error);
        }
      );
    } else {
      this.loginService.openDialogLogin();
    }
  }

  ngOnDestroy(): void {
    /* if (this.updatesSubscriptions != null) {
      this.updatesSubscriptions.unsubscribe();
    }
    if (this.servicosSubscription != null) {
      this.servicosSubscription.unsubscribe();
    } */
  }
}
