import { LocalStorageService } from 'ngx-webstorage';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService) { }

  fazerLogin(json: string) {
    const url = environment.API_EXTERNO + '/wslogin';
    const httpheaders = new HttpHeaders({'Content-Type': 'application/json'});
    const options = { headers: httpheaders };
    return this.http.post(url, json, options);
  }

  gerarTokenDashboardBI(json: string) {
    const url = environment.API_EXTERNO + '/token_dashboard';
    const httpheaders = new HttpHeaders({'Content-Type': 'application/json',
    Authorization: 'Bearer ' + this.localStorage.retrieve('token')});
    const options = { headers: httpheaders };
    return this.http.post(url, json, options);
  }

  buscaServicos(query: string) {
    // Formato da query: '?param=' + var
    const url = environment.API_EXTERNO + '/servicos';
    const httpheaders = new HttpHeaders({'Content-Type': 'text/plain',
      Authorization: 'Bearer ' + this.localStorage.retrieve('token')});
    const options = { headers: httpheaders };
    return this.http.get<any>(url + query, options);
  }

  verificaToken() {
    const json = JSON.stringify({
      token: this.localStorage.retrieve('token')
    });
    const url = environment.API_EXTERNO + '/verificatoken';
    const httpheaders = new HttpHeaders({'Content-Type': 'application/json'});
    const options = { headers: httpheaders };
    return this.http.post(url, json, options);
  }

  buscaOcorrenciasPorData(query: string) {
    // Formato da query: '?param=' + var
    const url = environment.API_SIOCB + '/ocorrencia-por-data';
    const httpheaders = new HttpHeaders({'Content-Type': 'text/plain',
      Authorization: 'Bearer ' + this.localStorage.retrieve('token')});
    const options = { headers: httpheaders };
    return this.http.get<any>(url + query, options);
  }
}
