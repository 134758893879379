<div class="container">
  <section class="row-card-link">
    <div class="card-link" *ngFor="let servico of servicos">
      <card-link
        titulo="{{ servico.titulo }}"
        descricao="{{ servico.descricao }}"
        icone="{{ servico.icone }}"
        path="{{ servico.path }}"
        type="{{ servico.tipo }}">
      </card-link>
    </div>
  </section>
</div>
<app-spinner></app-spinner>
