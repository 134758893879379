import { FlexLayoutModule } from '@angular/flex-layout';
// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
// Componentes
import { DetalhesImpressaoComponent } from './detalhes-impressao/detalhes-impressao.component';
import { ExtratoOcorrenciasComponent } from './extrato-ocorrencias.component';
// Modulos
import { ExtratoOcorrenciasRoutingModule } from './extrato-ocorrencias-routing.module';
import { MaterialModule } from 'src/material.module';

@NgModule({
  declarations: [
    ExtratoOcorrenciasComponent,
    DetalhesImpressaoComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MaterialModule,
    ExtratoOcorrenciasRoutingModule
  ],
  exports: [

  ]
})
export class ExtratoOcorrenciasModule { }
