import { SharedService } from './shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogLoginComponent } from './shared/dialog-login/dialog-login.component';
import { MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sistemas';

  constructor() { }
}
