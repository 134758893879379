import { DialogLoginService } from './dialog-login/dialog-login.service';
import { SnackbarService } from './snackbar/snackbar.service';
import { SharedService } from './shared.service';
import { NgxMaskModule, IConfig } from 'ngx-mask-2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/material.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { CardLinkComponent } from './card-link/card-link.component';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    ToolbarComponent,
    CardLinkComponent,
    DialogLoginComponent,
    SpinnerComponent,
    SnackbarComponent,
    PageNotFoundComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot(options),
    NgxSpinnerModule,
    MaterialModule
  ],
  exports: [
    ToolbarComponent,
    CardLinkComponent,
    DialogLoginComponent,
    SpinnerComponent,
    PageNotFoundComponent
  ],
  entryComponents: [
    DialogLoginComponent,
    SpinnerComponent
  ],
  providers: [
    DatePipe,
    SharedService,
    SnackbarService,
    DialogLoginService
  ]
})
export class SharedModule { }
