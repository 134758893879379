<a (click)="openPath()" class="div-mat-card">
  <mat-card>
    <mat-icon mat-card-md-image aria-hidden="true" aria-label="Example home icon" color="accent">{{ icone }}</mat-icon>
  <mat-card-content>
    <p class="titulo" color="aceent">
      {{ titulo }}
    </p>
    <p class="descricao" color="accent">
      {{ descricao }}
    </p>
  </mat-card-content>
</mat-card>
</a>

