import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from '../../app.service';
import { LocalStorageService } from 'ngx-webstorage';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';

@Component({
  selector: 'app-acidentes-transito',
  templateUrl: './acidentes-transito.component.html',
  styleUrls: ['./acidentes-transito.component.css']
})
export class AcidentesTransitoComponent implements OnInit {
  urlSafe: SafeResourceUrl = null;
  constructor(private sanitizer: DomSanitizer,
    private localStorage: LocalStorageService,
    private appService: AppService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.gerarTokenDashboardBI(100);
  }

  gerarTokenDashboardBI(numDashboard: number) {
    const jsonDashboard = JSON.stringify({
      dashboard: numDashboard
    });
    this.spinner.show();
    this.appService.gerarTokenDashboardBI(jsonDashboard).subscribe(
      (resultJSON: JSON) => {
        console.log(resultJSON);
        this.spinner.hide();
        if (!resultJSON['error']) {
          const url = 'https://bi.bombeiros.to.gov.br/embed/dashboard/' + resultJSON['token']
          + '#bordered=false&titled=false&origin=https://sistemas.bombeiros.to.gov.br';
          console.log(url);
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        } else {
          this.snackbar.erro('Falha o gerar token');
        }
      }, error => {
        this.snackbar.erro('Falha o gerar token. Aguarde um pouco ou procure a ASSEINFO');
        this.spinner.hide();
        console.log(error);
      }
    )
  }

}
