<div class="main-wrapper container">
  <div class="grid">
    <div class="icone">
      <mat-icon mat-md-image aria-hidden="false" aria-label="Example home icon" color="accent">coffee</mat-icon>
    </div>
    <div class="texto">
      <p>PÁGINA NÃO ENCONTRADA</p>
    </div>
  </div>
</div>



